import Vue from 'vue'
import VueGtag from 'vue-gtag'
import Platform from '@monogrid/js-utils/lib/Platform'

/**
 *  Prevent double "page_view" event, disable "Page changes based on browser history events"
 *  DOC: https://developers.google.com/analytics/devguides/collection/ga4/page-view#measure_virtual_pageviews
 *
 *  Steps:
 *    - Open an Analitycs data stram
 *    - Enable "Enhanced measurement"
 *    - Click on settings icon
 *    - Click on "Show advanced settings"
 *    - Disable "Page changes based on browser history events"
 *
 *  Don't use a $gtag.pageview() method in App.vue but in the page component.
 */

/* eslint-disable */
const url = window.location.href
const embedMode = (new URL(url)).searchParams.get('embed')
/* eslint-enable */

// Default production GTAG ID
const id = !embedMode || embedMode === '' || embedMode === '1' ? 'G-BQDJN0CEYW' : 'G-R1EH0DJ32X'

if (!Platform.prerenderer) {
  Vue.use(VueGtag, {
    config: {
      id,
      params: {
        send_page_view: false
      }
    },
    enabled: false,
    pageTrackerEnabled: false
  })
}
