<template>
  <Transition>
    <v-container v-show="visible" fluid fill-height>
      <v-row>
        <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="4" offset-xl="4" align="center" align-self="center">
          <div v-if="!illegal">
            <p class="anim interaction-text">{{$root.translations[$language.current].ageDetectorTitle}}</p>
            <h1 class="anim my-3 my-md-6">{{$root.translations[$language.current].ageDetectorDOB}}</h1>
            <p class="anim interaction-text">{{$root.translations[$language.current].ageDetectorDisclaimer}}</p>
            <div class="anim">
              <form>
                <div  class="d-flex align-center justify-center">
                  <input
                    v-model="month"
                    type="number"
                    :placeholder="$root.translations[$language.current].ageDetectorMonth"
                    :min="1"
                    :max="12"
                    :class="{'error': monthError}"
                    class="month"
                    autofocus
                  >
                  <div class="divider"></div>
                  <input
                    v-model="year"
                    type="number"
                    :placeholder="$root.translations[$language.current].ageDetectorYear"
                    :max="new Date().getFullYear()"
                    :class="{'error': yearError}"
                    class="year"
                  >
                </div>
                <div class="remember">
                  <div class="checkbox-round mr-2" :class="{'checked': rememberMe}" @click="rememberMe = !rememberMe"></div>
                  <p class="interaction-text">{{$root.translations[$language.current].ageDetectorRemember}}</p>
                </div>
              </form>
            </div>
          </div>
          <div v-else>
            <h1 class="anim my-6">{{$root.translations[$language.current].ageInvalidTitle}}</h1>
            <MarkdownBlock :text="$root.translations[$language.current].ageInvalidContent" class="anim gold" />
          </div>
        </v-col>
        <v-col cols="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="4" offset-xl="4" align-self="end">
          <p class="anim note">{{$root.translations[$language.current].ageDetectorFooter}}</p>
        </v-col>
      </v-row>
    </v-container>
  </Transition>
</template>

<script>
import MarkdownBlock from '@/atoms/MarkdownBlock.vue'

export default {
  name: 'AgeGate',
  components: { MarkdownBlock },
  data () {
    return {
      month: null,
      year: null,
      rememberMe: true,
      monthError: false,
      yearError: false,
      illegal: false,
      visible: false
    }
  },
  mounted () {
    if (this.$language.current === 'ko') {
      this.visible = true
    } else if (!this.$root.embedMode || this.$root.embedMode === '2') {
      const cookieSalute = document.cookie.split('; ').find(row => row.startsWith('royalsalutelegal'))
      if (!cookieSalute) this.visible = true
    }
  },
  methods: {
    checkDate () {
      this.monthError = false
      this.yearError = false

      if (this.month) {
        this.month = this.month.slice(0, 2)
      }

      if (this.year) {
        this.year = this.year.slice(0, 4)
      }

      const yInt = parseInt(this.year)
      const mInt = parseInt(this.month)

      if (this.month) {
        if (this.month.length < 1) {
          this.monthError = true
        }

        if (mInt > 12) {
          this.monthError = true
        }
      }

      if (this.year) {
        if (this.year.length < 4) {
          this.yearError = true
        }

        if (yInt > 2021 || yInt < 1900) {
          this.yearError = true
        }
      }

      if (this.month && this.year && !this.monthError && !this.yearError) {
        const today = new Date()
        let age = today.getFullYear() - this.year
        if (today.getMonth() < this.month || (today.getMonth() === this.month && today.getDate() < 1)) {
          age--
        }

        if (age < 18) {
          this.illegal = true
          this.$root.pushAnalyticsEvent('age_illegal', '', '', '', '')
        } else {
          this.visible = false
          this.illegal = false
          this.$root.pushAnalyticsEvent('age_legal', '', '', '', '')
          if (this.rememberMe) {
            document.cookie = 'royalsalutelegal=true; max-age=15552000;'
          }
        }
      }
    }
  },
  watch: {
    month () {
      this.checkDate()
    },
    year () {
      this.checkDate()
    },
    '$language.current': {
      handler: function (newValue, oldValue) {
        this.visible = newValue === 'ko' && !(this.month || this.year)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  inset: 0;
  z-index: $z-age-gate;
  background-color: $c-blue;

  @include breakpoint ('md-and-up') {
    padding-top: 80px;
  }

  .row {
    min-height: calc(100% - 50px);
    padding-top: 80px;

    @include breakpoint ('md-and-up') {
      min-height: calc(100% - 30px);
      padding-top: 40px;
    }
  }
}

.note {
  font: 10px/13px futura-pt;
  letter-spacing: 2px;
  color: $c-white;
  text-transform: uppercase;
  text-align: center;
}

form {
  width: 100%;
  height: 100px;
  margin-top: 45px;

  input[type='number'] {
    width: 50%;
    display: inline-block;
    outline: none;
    appearance: textfield;
    font-size: 3.5vw;
    letter-spacing: 14px;
    color: $c-white;
    text-align: center;
    transition: color 0.3s;

    @include breakpoint('sm-and-down') {
      font-size: 30px;
    }

    &.month {
      width: 140px;

      @include breakpoint('sm-and-down') {
        width: 70px;
      }
    }

    &.year {
      width: 240px;

      @include breakpoint('sm-and-down') {
        width: 140px;
      }
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
    }

    &.error {
      color: $c-red;
    }
  }

  .divider {
    width: 20px;
    height: 4px;
    margin: 0 24px;
    background-color: $c-white;

    @include breakpoint('sm-and-down') {
      width: 16px;
      height: 2px;
      margin: 0 12px;
    }
  }
}

.remember {
  margin-top: 45px;

  .checkbox-round,
  p {
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
  }
}

.checkbox-round {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid $c-gold;
  position: relative;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: $c-white;
    transform: scale(0);
    top: 5px;
    left: 5px;
    transition: transform 0.5s;
  }

  &.checked::before {
    transform: scale(1);
  }
}
</style>
