export const AVAILABLE_LOCALES = {
  embedMode0: [ // STANDALONE
    { code: 'en', label: 'Eng' },
    { code: 'cn', label: 'Chs' },
    { code: 'tw', label: 'Twn' },
    { code: 'ko', label: 'Kor' }
  ],
  embedMode1: [ // CAG
    { code: 'en', label: 'Eng' },
    { code: 'cn', label: 'Chs' },
    { code: 'zh', label: 'Cht' },
    { code: 'tw', label: 'Twn' },
    { code: 'ko', label: 'Kor' }
  ],
  embedMode2: [ // LOTTE
    { code: 'en', label: 'Eng' },
    { code: 'cn', label: 'Chs' },
    { code: 'zh', label: 'Cht' },
    { code: 'tw', label: 'Twn' },
    { code: 'ko', label: 'Kor' }
  ],
  embedMode3: [ // KIX
    { code: 'en', label: 'Eng' },
    { code: 'cn', label: 'Chs' },
    { code: 'zh', label: 'Cht' },
    { code: 'tw', label: 'Twn' },
    { code: 'ko', label: 'Kor' },
    { code: 'ja', label: 'Jap' }
  ]
}
