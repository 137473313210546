<template>
  <v-fade-transition>
    <div v-if="$vuetify.breakpoint.mdAndUp && !$route.params.contentSlug" class="shop">
      <div v-click-outside="() => { openShop = false }" class="cta">
        <div class="line"></div>
        <button-text
          @click="openShop = !openShop"
          :class="{ clicked: openShop }"
          :decoration="false"
        >
          {{$root.translations[$language.current].shopCollection}}
        </button-text>
        <div class="line"></div>
      </div>
      <div
        :class="{ open: openShop }"
        :style="{
          maxHeight: products.length * 55 + 'px',
          width: getMaxWidth
        }"
        class="content-container py-4 px-2 text-center"
      >
        <button-text
          v-for="(product, index) in products"
          :key="index"
          :tag="product[`${$language.current}ShopURL${$root.embedMode}`] ? 'a' : 'router-link'"
          :to="product[`${$language.current}ShopURL${$root.embedMode}`]
            ? undefined
            : {name:'Room',params:{roomSlug:product.roomSlug,contentSlug:product._slug}
          }"
          :href="product[`${$language.current}ShopURL${$root.embedMode}`]
            ? product[`${$language.current}ShopURL${$root.embedMode}`]
            : undefined
          "
          :target="product[`${$language.current}ShopURL${$root.embedMode}`]
            ? '_blank'
            : undefined
          "
          class="my-2"
          @click.native="onProductClick(product)"
        >
          {{product[$language.current + 'Title']}}
        </button-text>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import GetShop from '@/graphql/GetShop.gql'
import ButtonText from '../atoms/ButtonText.vue'

export default {
  components: { ButtonText },
  apollo: {
    Rooms: {
      query: GetShop,
      skip () {
        return this.$vuetify.breakpoint.smAndDown
      },
      async result () {
        let temp = []
        for (let i = 0; i < this.Rooms.items.length; i++) {
          const room = this.Rooms.items[i]
          const products = room.hotspot.filter((h) => {
            if (h.contentId.type !== 'Product') return false
            if (!h.contentId.visibleOnlyEmbedModes) return true
            if (h.contentId.visibleOnlyEmbedModes.includes(this.$root.embedMode)) return true
            return false
          })
          const map = products.map(({ contentId }) => {
            return { ...contentId, roomSlug: room._slug }
          })
          temp = temp.concat(map)
        }
        this.allProcuts = temp
      }
    }
  },
  data () {
    return {
      openShop: false,
      allProcuts: [],
      roomProducts: []
    }
  },
  computed: {
    products () {
      return this.allProcuts
    },
    getMaxWidth () {
      let char = 0
      const multiplier = 12

      for (let i = 0; i < this.products.length; i++) {
        const element = this.products[i]
        if (element.title.length > char) {
          char = element.title.length
        }
      }

      return char * multiplier + 'px'
    }
  },
  methods: {
    onProductClick (product) {
      this.$root.pushAnalyticsEvent('click_on_shop_product', 'UI button', 'click', `${product.enTitle}`, '')
      this.$root.menuOpen = false
      this.openShop = false
    }
  }
}
</script>

<style lang="scss" scoped>
.shop {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: $z-ui-el;

  html[lang='tw'] & {
    bottom: 100px;
  }
}

/* stylelint-disable */
.cta {
  &:hover .line {
    opacity: 1;
  }

  ::v-deep .button-text.clicked .light {
    width: 100%;
  }

  .line {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 1px;
    background-color: $c-gold;
    opacity: 0.5;
    transition: opacity 0.3s;
  }

  ::v-deep .button-text {
    display: inline-block;
    vertical-align: middle;
    margin: 0 12px;
    text-transform: uppercase;
    font: 18px/24px futura-pt;
    letter-spacing: 2.8px;

    .original,
    .dark,
    .light,
    p {
      font: inherit;
    }
  }
}
/* stylelint-enable */

.content-container {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: fit-content;
  transform: translateX(-50%);
  background-color: $c-blue;
  box-shadow: 0 3px 6px $c-black-10;
  border: 1px solid $c-white-30;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.open {
    opacity: 1;
    pointer-events: all;
  }
}

</style>
