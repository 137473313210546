<template>
  <transition mode="out-in">
    <span :key="text" class="drink-responsibly-disclaimer" v-html="text" />
  </transition>
</template>

<script>
import TwIcon from '@/assets/img/tw_icon.png'

export default {
  name: 'DisclaimerText',
  data () {
    return {
      TwIcon
    }
  },
  computed: {
    text () {
      const _text = this.$root.productDiscalimerText || this.$root.translations[this.$language.current].drinkResponsiblyDisclaimer || this.$root.translations.en.drinkResponsiblyDisclaimer
      return _text.replace('[icon]', `<img src="${TwIcon}" />`)
    }
  }
}
</script>

<style lang="scss" scoped>
.drink-responsibly-disclaimer {
  position: fixed;
  width: 100%;
  min-height: 40px;
  padding: 4px 12px;
  left: 0;
  right: 0;
  bottom: 56px;
  color: rgba($c-white, 0.6);
  left: 50%;
  transform: translateX(-50%);
  z-index: $z-ui-el;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.2px;
  font: 10px/12px futura-pt;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint ('md-and-up') {
    bottom: 0;
    max-width: 75%;
  }

  html[lang='tw'] & {
    background-color: $c-black-100;
    max-width: 100%;
    height: 80px;
    font-size: 6vw;

    @include breakpoint ('md-and-up') {
      font-size: 3vw;
      letter-spacing: 30px;
    }
  }

  &::before {
    @include breakpoint ('sm-and-down') {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 150%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 50%);
      z-index: -1;
    }
  }
}

::v-deep img {
  height: 1.15em;

  @include breakpoint ('md-and-up') {
    margin-right: 24px;
  }
}
</style>
