<template>
  <div @click="toogleAudio" :class="{ 'd-none': !src , 'play': isPlaying, 'lotte-app': $root.isLotteApp }" class="audioplayer" >
    <audio
      ref="audio"
      :src="'/media/' + src"
      :loop="true"
      :autoplay="autoplay"
      preload="auto"
      @play="onAudioPlay"
      @pause="onAudioPause"
      class="d-none"
    />
    <button-text>{{$root.translations[$language.current].sound}}</button-text>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import ButtonText from '@/atoms/ButtonText.vue'

export default {
  name: 'AudioPlayer',
  components: { ButtonText },
  data () {
    return {
      wasPlaying: false,
      isPlaying: false,
      src: null
    }
  },

  async mounted () {
    await this.$nextTick()
    this.createTimeline()

    this.$events.on('page-has-audio', this.onRoomChange)
    this.$events.on('page-has-no-audio', this.onRoomChange)

    this.$events.on('play-audio', this.playAudio)
    this.$events.on('stop-audio', this.pauseAudio)

    document.addEventListener('visibilitychange', () => {
      if (!this.player) return
      if (document.visibilityState === 'visible') {
        if (this.wasPlaying) this.player.play()
      } else {
        this.player.pause()
      }
    })
  },

  computed: {
    player () {
      return this.$refs.audio
    },
    autoplay () {
      if (this.$root.embedMode === '2' || this.$root.isLotteApp) return false
      return this.$route.name === 'Room' && this.wasPlaying
    }
  },

  methods: {
    createTimeline () {
      this.tl = gsap.timeline({ paused: true })
      this.tl.addLabel('pause')
      this.tl.fromTo(this.player, { volume: 0 }, { volume: 1, duration: 2 })
      this.tl.addLabel('play')
    },
    toogleAudio () {
      this.$root.pushAnalyticsEvent('click_on_sound', 'UI button', 'click', `${this.isPlaying ? 'OFF' : 'ON'}`, '')

      if (this.isPlaying) {
        this.pauseAudio()
      } else {
        this.playAudio()
      }
      this.wasPlaying = !this.isPlaying
    },
    playAudio () {
      this.player.play()
    },
    pauseAudio () {
      this.tl.tweenTo('pause', { onComplete: () => { this.player.pause() } })
    },
    onAudioPlay () {
      this.$events.$emit('room-audio-play')
      this.isPlaying = true
      this.wasPlaying = true
      this.tl.tweenTo('play')
    },
    onAudioPause () {
      this.$events.$emit('room-audio-pause')
      this.isPlaying = false
    },
    onRoomChange (audio) {
      if (this.$route.name === 'Home') this.wasPlaying = false
      if (this.$route.name === 'Room' && (this.$root.embedMode === '2' || this.$root.isLotteApp)) this.wasPlaying = this.autoplay
      else if (this.$route.name === 'Room') this.wasPlaying = true
      if (this.src === audio) return
      if (!this.isPlaying) {
        this.src = audio
      } else {
        this.tl.tweenTo('pause', {
          onComplete: () => {
            this.player.pause()
            this.src = audio
            if (audio && this.wasPlaying) this.playAudio()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.audioplayer {
  position: fixed;
  left: 40px;
  top: 30px;
  z-index: $z-ui-el;
  display: flex;

  @include breakpoint('sm-and-down') {
    top: 30px;
    left: 20px;
  }

  &.play ::v-deep .light {
    width: 100%;
  }

  &.lotte-app {
    top: 65px;
  }
}
</style>
