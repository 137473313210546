import packageConfig from '../package.json'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Platform from '@monogrid/js-utils/lib/Platform'

import router from './plugins/router.js'
import { optIn, optOut } from 'vue-gtag'
import './plugins/analytics.js'
import './plugins/locale.js'
import VueMeta from 'vue-meta'
import VueEvents from 'vue-event-handler'
import Preloader from './preloader'
import PortalVue from 'portal-vue'
import onlineApolloProvider from './services/CMS'
// import CMSOffline from './services/CMSOffline'
// import cmsOfflineCatalog from './services/CMSOfflineCatalog.json'
// import cmsOfflineSchema from './services/CMSOfflineSchema.graphql'
import GetSettings from '@/graphql/GetSettings.gql'
import ListTranslation from '@/graphql/ListTranslation.gql'

// usage: https://vuetifyjs.com/
import vuetify from './plugins/vuetify'

// usage: https://github.com/declandewet/vue-meta
Vue.use(VueMeta)
// usage: https://github.com/sandeepk01/vue-event-handler
Vue.use(VueEvents)
// usage: https://github.com/LinusBorg/portal-vue
Vue.use(PortalVue)

//
//
// Initialization of the preloader app (placed on the HTML)
//
//
const preloaderInstance = new Vue(Preloader)

// management of languages
router.beforeEach((to, from, next) => {
  // set current language on change route
  preloaderInstance.visible = true
  if (packageConfig.multilanguage) {
    if (to.params.language) {
      Vue.config.language = to.params.language || 'en'
    }
  } else {
    Vue.config.language = 'en'
  }
  next()
})
router.afterEach((to, from) => {
  preloaderInstance.visible = false
})
// const USE_OFFLINE = false
//
//
// Initialization of the app (placed on the HTML)
//
//
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  vuetify,
  /**
   * this is an example of using the online server in all the stages
   * and using offline data in a production stage (for example if deployed on client servers)
   * rename the production stage to "production" if you want to use this configuration
   */
  // apolloProvider: USE_OFFLINE ? new CMSOffline(cmsOfflineCatalog, cmsOfflineSchema).getProvider() : onlineApolloProvider,
  /**
   * use this if you want the online-only version of the CMS data
   */
  apolloProvider: onlineApolloProvider,
  apollo: {
    Settings: {
      query: GetSettings,
      variables () {
        return {
          slug: 'global'
        }
      }
    },
    Translations: {
      query: ListTranslation,
      variables () {
        return {
          status: 'PUBLISHED'
        }
      },
      result () {
        for (let i = 0; i < this.Translations.items.length; i++) {
          const element = this.Translations.items[i]
          this.translations[element._slug] = element
        }
        this.translationsReady = true
      }
    }
  },
  data () {
    return {
      preloader: preloaderInstance,
      process: {
        env: process.env
      },
      translations: [],
      menuOpen: false,
      translationsReady: false,
      embedMode: '0',
      useAnalytics: false,
      productDiscalimerText: null
    }
  },
  computed: {
    platform () {
      return Platform
    },
    multilanguage () {
      return packageConfig.multilanguage
    },
    isLotteApp () {
      // console.log(navigator.userAgent.indexOf('NativeDfs') !== -1)
      // return navigator.userAgent.indexOf('NativeDfs') !== -1
      return this.embedMode === '2'
    }
  },
  methods: {
    toggleAnalytics () {
      if (process.env.SERVICE_STAGE !== 'production') {
        this.useAnalytics = false
        optOut()
        return
      }
      if (localStorage.getItem('analytics-consent') === 'true') {
        optIn()
        this.useAnalytics = true
      } else {
        optOut()
        this.useAnalytics = false
      }
    },
    pushAnalyticsEvent (eventName, eventCategory, eventAction, eventLabel, eventValue) {
      if (!this.$gtag || !this.useAnalytics) return
      if (!window.dataLayer) window.dataLayer = []

      this.$gtag.event(eventName, {
        eventCategory,
        eventAction,
        eventLabel,
        eventValue
      })
    }
  },
  mounted () {
    this.$root.toggleAnalytics()
    if (!localStorage.getItem('show-banner')) {
      localStorage.setItem('show-banner', true)
    }
  },
  // this block allows passing props to the main App
  // these props are set by html-webpack-plugin in the html
  // and the values are taken from git-revision-webpack-plugin
  render (createElement) {
    return createElement(App, {
      props: {
        preloader: preloaderInstance
      }
    })
  }
})
