<template>
  <div v-click-outside="() => isVisible = false" class="lang-switcher d-none d-md-block">
    <ButtonText @click="isVisible = !isVisible" :class="{ clicked: isVisible }" class="current">
      {{currentLanguage && currentLanguage.label}}
    </ButtonText>
    <div class="languages py-4" :class="{ visible: isVisible }">
      <ButtonText
        v-for="{ label, code } in otherLanguages"
        :key="code"
        :to="{ params: { language: code }}"
        tag="router-link"
        class="my-2"
        replace
        @click.native="onClick(code)"
      >
        {{label}}
      </ButtonText>
    </div>
  </div>
</template>

<script>
import { AVAILABLE_LOCALES } from '@/assets/data/constants'
import ButtonText from './ButtonText.vue'

export default {
  name: 'LangSwitcher',
  components: { ButtonText },
  data () {
    return { isVisible: false }
  },
  computed: {
    languages () {
      return AVAILABLE_LOCALES[`embedMode${this.$root.embedMode}`]
    },
    otherLanguages () {
      return this.languages.filter(l => l.code !== this.$language.current)
    },
    currentLanguage () {
      return this.languages.find(l => l.code === this.$route.params.language)
    }
  },
  methods: {
    onClick (language) {
      this.$root.pushAnalyticsEvent('click_on_language', 'UI button', 'click', `${language}`, '')
      this.isVisible = false
    }
  },
  watch: {
    currentLanguage: {
      handler (value) {
        if (!this.languages?.includes(value)) {
          this.$router.replace({ params: { language: 'en' } })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.lang-switcher {
  text-align: center;
  position: fixed;
  right: 40px;
  bottom: 30px;
  z-index: $z-ui-el;

  html[lang='tw'] & {
    bottom: 100px;
  }
}

.current.clicked {
  ::v-deep .light {
    width: 100%;
  }
}

.languages {
  width: 90px;
  background-color: $c-blue;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #ffffff1d;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -10px;
  bottom: 40px;
  z-index: $z-ui-el;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

</style>
