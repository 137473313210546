<template>
  <div v-click-outside="() => isVisible = false" class="share d-none d-md-block">
    <button-text @click="onClick()" type="button">{{$root.translations[$language.current].share}}</button-text>
    <div class="message pa-4" :class="{ visible: isVisible }">
      <p>{{$root.translations[$language.current].copiedToClipboard}}</p>
    </div>
  </div>
</template>

<script>
import ButtonText from './ButtonText.vue'

export default {
  name: 'ShareCta',
  components: { ButtonText },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    onClick () {
      this.$root.pushAnalyticsEvent('click_on_share', 'UI button', 'click', 'Share', '')
      const url = `${location.origin}/?embed=${this.$root.embedMode}`
      // eslint-disable-next-line
      navigator.clipboard.writeText(url).then( () => {
        console.log(`[URL] ${url} -> Copied to clipboard!`)
        clearTimeout(this.timeout)
        this.isVisible = true
        this.timeout = setTimeout(() => {
          this.isVisible = false
        }, 4000)
      }, (err) => {
        console.log(`[URL] ${url} -> Not copies, `, err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.share {
  position: fixed;
  left: 40px;
  bottom: 30px;
  z-index: $z-ui-el;

  html[lang='tw'] & {
    bottom: 100px;
  }
}

.message {
  min-width: 240px;
  background-color: $c-blue;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #ffffff1d;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -10px;
  bottom: 40px;
  z-index: $z-ui-el;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  p {
    letter-spacing: 2.4px;
    font: 12px/15px futura-pt;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
</style>
