<script>
import MarkdownIt from 'markdown-it'
import mila from 'markdown-it-link-attributes'
import customBlock from 'markdown-it-custom-block'
import container from 'markdown-it-container'
/**
 * A component that renders markdown text in Vue using **markdown-it** and some of its plugins.
 */
export default {
  props: {
    /**
     * The text to be displayed, should be formatted in markdown syntax
     */
    text: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * Decides whether block tags are rendered or not, example:
     *
     * input
     *
     * ```md
     * some paragraph
     *
     * some other paragraph
     * ```
     *
     * output with true
     *
     * ```html
     * &lt;div&gt;
     *   &lt;p&gt;some paragraph&lt;/p&gt;
     *   &lt;p&gt;some other paragraph&lt;/p&gt;
     * &lt;/div&gt;
     * ```
     *
     * output with false
     *
     * ```html
     * &lt;div&gt;some paragraph some other paragraph&lt;/div&gt;
     * ```
     * @see [markdown-it inline rendering](https://github.com/markdown-it/markdown-it#simple)
     */
    inline: {
      type: Boolean,
      default: false
    },
    /**
     * The base root tag of this element, can be any tag (div, span, p, etc)
     *
     * Should be a valid html tag.
     */
    tag: {
      type: String,
      default: 'div'
    },
    /**
     * Autoconvert URL-like text to links
     *
     * @see [markdown-it docs](https://github.com/markdown-it/markdown-it#init-with-presets-and-options)
     */
    linkify: {
      type: Boolean,
      default: false
    },
    /**
     * Enable some language-neutral replacement + quotes beautification
     *
     * @see [markdown-it docs](https://github.com/markdown-it/markdown-it#init-with-presets-and-options)
     */
    typographer: {
      type: Boolean,
      default: false
    },
    /**
     * Hyperlinks Attributes, applied to all hyperlinks.
     *
     * This uses **markdown-it-link-attributes**, the object is the configuration for the plugin
     *
     * @see [markdown-it-link-attributes docs](https://github.com/crookedneighbor/markdown-it-link-attributes)
     */
    linksAttributes: {
      type: Object,
      default () {
        return {
          attrs: {
            target: '_blank',
            rel: 'noopener'
          }
        }
      }
    },
    /**
     * Object containing any number of **markdown-it-custom-block** functions
     *
     * Each function should have:
     *
     * * the name of the custom block as function name
     * * parser for the custom block as the body of the function
     *
     * @see [markdown-it-custom-block docs](https://github.com/posva/markdown-it-custom-block)
     */
    customBlocks: {
      type: Object,
      default () {
        return {
          'mark' (arg) {
            return `<mark>${arg}</mark>`
          }
        }
      }
    },
    /**
     * Object containing any number of **markdown-it-container**
     * configuration objects indexed by their container name, like this:
     *
     * |       key      |            value               |
     * |----------------|--------------------------------|
     * | container-name | { validate: ..., render: ... } |
     *
     * markdown-it-container is a plugin for creating block-level custom containers for markdown-it markdown parser.
     *
     * @see [markdown-it-container docs](https://github.com/markdown-it/markdown-it-container)
     */
    customContainers: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  created () {
    this.buildMd()
  },

  render (createElement) {
    // const t = this.getTemplate(this.$props.text, this.$props.inline, this.$props.tag)
    // const compiled = Vue.compile(t)

    // // create a new scope for the compiled markdown template
    // const scope = {
    //   _c: this.$parent._c,
    //   _v: this.$parent._v,
    //   _m: this.$parent._m,
    //   _staticTrees: [],
    //   _renderProxy: this.$parent._renderProxy,
    //   $options: {
    //     staticRenderFns: compiled.staticRenderFns
    //   }
    // }
    // console.log('rendering markdown')
    // return createElement({ name: 'MarkdownBlock', render: compiled.render.bind(scope) })
    const c = this.$props.inline ? this.md.renderInline(this.$props.text) : this.md.render(this.$props.text)
    // debugger
    return createElement(this.$props.tag, { domProps: { innerHTML: c } })
  },

  watch: {
    linkify (value) {
      this.buildMd()
      this.$forceUpdate()
    },
    typographer (value) {
      this.buildMd()
      this.$forceUpdate()
    },
    linksAttributes (value) {
      this.buildMd()
      this.$forceUpdate()
    },
    customBlocks (value) {
      this.buildMd()
      this.$forceUpdate()
    },
    customContainers (value) {
      this.buildMd()
      this.$forceUpdate()
    }
  },

  methods: {
    buildMd () {
      this.md = new MarkdownIt({
        linkify: this.linkify,
        typographer: this.typographer
      })
      this.md.use(mila, this.linksAttributes)
      this.md.use(customBlock, this.customBlocks)

      if (this.customContainers) {
        Object.keys(this.customContainers).forEach(key => {
          this.md.use(container, key, this.customContainers[key])
        })
      }
    },
    getTemplate (text, inline = false, rootTag = 'span') {
      // check if root tag exists when inline = true
      if (inline === true && !rootTag) {
        throw new Error('[MarkdownParser] When using inline option you must specify a root tag')
      }
      let templateMarkdown
      if (inline) {
        // we parse this with markdown
        // and add a root tag of our choice
        templateMarkdown = (rootTag ? '<' + rootTag + '>' : '') + this.md.renderInline(text) + (rootTag ? '</' + rootTag + '>' : '')
      } else {
        // or use this if you have multiline elements or you want a <p> tag as root of a single line
        templateMarkdown = (rootTag ? '<' + rootTag + '>' : '') + this.md.render(text) + (rootTag ? '</' + rootTag + '>' : '')
      }

      // QUICK HACK:
      // [Else](Something "Optional") usually get compiled to html links
      // so we replace any <a href="Something" title="Optional">Else</a>

      // with <router-link :to="{name: 'Something' params: {Optional}}">Else</router-link>
      // NOTE: this should leave alone links wich contains non-word characters like . or / so external links still work
      // const template = templateMarkdown.replace(/<a href="([\w]+)"[ ]*(title="([^"]*)")?[^>]*>([^<]+)<\/a>/igm, (match, p1, p2, p3, p4) => {
      //   return '<router-link :to="{name: \'' + p1 + '\'' + (p2 ? ', params: {' + p3 + '}' : '') + '}">' + p4 + '</router-link>'
      // })
      // return a new component so that router-links get parsed and rendered
      return templateMarkdown
    }
  }
}
</script>
