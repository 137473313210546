<template>
  <v-app id="app" class="app" v-if="!$root.platform.prerenderer && $root.translationsReady && $root.Settings">
    <div v-if="$root.isLotteApp" class="lotte-app-button">
      <a href="lottedfs://call?class=ConfigManager&method=nativeBack&callbackId=nativeBackCB&params={}" class="lotte-app-button-back">
        <BackSvg />
      </a>
      <a href="lottedfs://call?class=ConfigManager&method=nativeClose&callbackId=nativeCloseCB&params={}" class="lotte-app-button-close">
        <CloseSvg />
      </a>
    </div>
    <v-main>
      <router-link
        :to="{ name: 'Home', params: { language: $language.current } }"
        @click.native="$root.pushAnalyticsEvent('click_on_logo', 'UI button', 'click', 'Logo', '')"
        class="logo-container"
      >
        <LogoSvg />
      </router-link>
      <transition mode="out-in" appear>
        <div v-if="$route.name !== 'CookiePage'">
          <MenuMobile />
          <Menu />
          <AudioPlayer />
          <MenuCta />
          <ShareCta />
          <LangSwitcher />
          <Shop />
          <DisclaimerText />
        </div>
      </transition>
      <CookieBanner />
      <transition mode="out-in" appear>
        <router-view :key="$route.name" />
      </transition>
    </v-main>
    <AgeGate />
    <!-- <VersionInfo
      v-if="$root.process.env.SERVICE_STAGE !== 'production'"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
    /> -->
  </v-app>
</template>

<script>
import Menu from './organisms/Menu.vue'
import MenuMobile from './organisms/MenuMobile.vue'
import Shop from '@/molecules/Shop.vue'
// import VersionInfo from '@monogrid/vue-lib/lib/components/VersionInfo'
import LogoSvg from '@/assets/svg/rs-logo.svg'
import AudioPlayer from '@/atoms/AudioPlayer.vue'
import MenuCta from '@/atoms/MenuCta.vue'
import ShareCta from '@/atoms/ShareCta.vue'
import LangSwitcher from '@/atoms/LangSwitcher.vue'
import CookieBanner from '@/organisms/CookieBanner'

import BackSvg from '@/assets/svg/arrow.svg'
import CloseSvg from '@/assets/svg/close.svg'
import DisclaimerText from './atoms/DisclaimerText.vue'
import AgeGate from '@/organisms/AgeGate.vue'

let initializedModernizr = false

export default {
  name: 'App',
  components: {
    /* VersionInfo, */ Menu,
    MenuMobile,
    LogoSvg,
    Shop,
    AudioPlayer,
    MenuCta,
    ShareCta,
    LangSwitcher,
    CookieBanner,
    BackSvg,
    CloseSvg,
    DisclaimerText,
    AgeGate
  },
  async mounted () {
    if (this.$root.isLotteApp) {
      window.open('lottedfs://call?class=ConfigManager&method=hiddenActionbarOnCurrentURL&callbackId=hiddenActionbarOnCurrentURL1&params={"url":""}', '_self')
    }
  },
  updated () {
    // if not prerenderer
    if (!this.$root.platform.prerenderer && window.Modernizr && !initializedModernizr) {
      // add custom modernizr tests
      initializedModernizr = true
      // window.Modernizr.addAsyncTest('avif', this.testAvif)
      for (const key in this.$root.platform) {
        window.Modernizr.addTest(key, () => {
          return this.$root.platform[key]
        })
      }
    }
  },
  watch: {
    isReady (value) {
      this.$root.preloader.visible = !value
    },
    $route (to, from) {
      if (to.query.embed) {
        /*
          undefined = no embed version
          '1' = CAG version
          '2' = LOTTE version
          '3' = JAPAN version
        */
        this.$root.embedMode = to.query.embed
      }
    }
  },
  metaInfo () {
    if (!this.$root.Settings) return
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$root.Settings?.seoTitle,
      // all titles will be injected into this template
      titleTemplate: '%s | ' + this.$root.Settings?.seoTitle,
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        // { name: 'twitter:card', content: 'summary_large_image' },
        // { name: 'twitter:site', content: '@monogridstudio' },
        // { name: 'twitter:creator', content: '@monogridstudio' },
        // generic description
        { vmid: 'description', name: 'description', content: this.$root.Settings?.seoDescription },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/' + this.$route.fullPath },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'Royal Salute' },
        { vmid: 'ogdescription', property: 'og:description', content: this.$root.Settings?.seoDescription },
        { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + '/media/' + this.$root.Settings?.seoImage.id },
        { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
        { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
        { vmid: 'ogimagewidth', property: 'og:image:height', content: '630' }
      ],
      htmlAttrs: {
        lang: this.$language.current
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://use.typekit.net/jga5xoo.css');
@import './helper';

.app {
  background-color: $c-blue;
  overflow: hidden;
}

.lotte-app-button {
  width: 100%;
  height: rem(60px);
  padding: rem(0 20px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  pointer-events: none;

  .lotte-app-button-back {
    width: rem(30px);
    height: rem(30px);
    pointer-events: all;

    svg {
      transform: rotate(-90deg);
      stroke-width: 1px;
    }
  }

  .lotte-app-button-close {
    width: rem(26px);
    height: rem(26px);
    pointer-events: all;

    svg {
      stroke-width: 1.5px;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.logo-container {
  position: fixed;
  z-index: $z-logo;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: rem(120px);
  height: rem(60px);

  svg {
    width: 100%;
    height: 100%;
  }
}

html,
body {
  height: 100%;
  overflow: hidden !important;

  .theme--light.v-application {
    height: 100%;
    color: $c-white;
    background-color: $c-blue;
    overflow-y: auto;
    overflow-x: hidden;

    p {
      margin-bottom: 0;
    }
  }
}

h1 {
  font: normal normal normal 70px/100px itc-galliard;

  @include breakpoint ('sm-and-down') {
    font: normal normal normal 30px/42px itc-galliard;
  }
}

h2 {
  font: normal normal normal 40px/56px itc-galliard;

  @include breakpoint ('sm-and-down') {
    font: normal normal normal 20px/28px itc-galliard;
  }
}

p,
span,
small,
li {
  font: normal normal normal 18px/24px futura-pt;

  @include breakpoint ('sm-and-down') {
    font: normal normal normal 14px/18px futura-pt;
    letter-spacing: 1px;
  }
}

.gold {
  color: $c-gold;
}

.bg-blur {
  backdrop-filter: blur(10px);

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  &::before {
    background-color: $c-blue-90;
    // mix-blend-mode: multiply;
  }

  &::after {
    background: radial-gradient(circle, rgba(0, 0, 0, 0) 60%, rgba(18, 32, 57, 1) 100%);
    z-index: 0;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.interaction-text {
  color: $c-gold;
  font: 16px/24px futura-pt;
  letter-spacing: 2.4px;

  @include breakpoint ('sm-and-down') {
    font: normal normal normal 12px/16px futura-pt;
  }
}

.list-style-none {
  list-style: none !important;
}

</style>
