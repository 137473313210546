var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-fade-transition',[(_vm.$vuetify.breakpoint.mdAndUp && !_vm.$route.params.contentSlug)?_c('div',{staticClass:"shop"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { _vm.openShop = false }),expression:"() => { openShop = false }"}],staticClass:"cta"},[_c('div',{staticClass:"line"}),_vm._v(" "),_c('button-text',{class:{ clicked: _vm.openShop },attrs:{"decoration":false},on:{"click":function($event){_vm.openShop = !_vm.openShop}}},[_vm._v("\n        "+_vm._s(_vm.$root.translations[_vm.$language.current].shopCollection)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"line"})],1),_vm._v(" "),_c('div',{staticClass:"content-container py-4 px-2 text-center",class:{ open: _vm.openShop },style:({
        maxHeight: _vm.products.length * 55 + 'px',
        width: _vm.getMaxWidth
      })},_vm._l((_vm.products),function(product,index){return _c('button-text',{key:index,staticClass:"my-2",attrs:{"tag":product[((_vm.$language.current) + "ShopURL" + (_vm.$root.embedMode))] ? 'a' : 'router-link',"to":product[((_vm.$language.current) + "ShopURL" + (_vm.$root.embedMode))]
          ? undefined
          : {name:'Room',params:{roomSlug:product.roomSlug,contentSlug:product._slug}
        },"href":product[((_vm.$language.current) + "ShopURL" + (_vm.$root.embedMode))]
          ? product[((_vm.$language.current) + "ShopURL" + (_vm.$root.embedMode))]
          : undefined,"target":product[((_vm.$language.current) + "ShopURL" + (_vm.$root.embedMode))]
          ? '_blank'
          : undefined},nativeOn:{"click":function($event){return _vm.onProductClick(product)}}},[_vm._v("\n        "+_vm._s(product[_vm.$language.current + 'Title'])+"\n      ")])}),1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }