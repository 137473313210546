<template>
  <div :class="{ grabbing: mouseDown }" class="image-scrubber">
    <div class="shadows"></div>
    <canvas
      v-if="imageArray && imageArray.length > 0"
      ref="canvas"
      :width="`${width}px`"
      :height="`${height}px`"
      @mousedown="onMouseDown"
      @mouseup="onMouseUp"
      @mousemove="onMouseMove"
      @touchstart="onMouseDown"
      @touchend="onMouseUp"
      @touchmove="onMouseMove"
      @mouseleave="onMouseUp"
      @touchleave="onMouseUp"
    />
  </div>
</template>

<script>
import { RAF, ElasticNumber } from '@monogrid/js-utils'
import axios from 'axios'

import filter from 'lodash/filter'
import JSZip from 'jszip'
import sortBy from 'lodash/sortBy'
import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'

export default {
  name: 'ImageScrubber',
  mixins: [Viewport],

  data () {
    return {
      mouseDown: false,
      scrubFrame: 0,
      frames: 0,
      imageDataArray: [],
      imageArray: null,
      alphaChannelImageArray: [],
      width: 1024,
      height: 1024,
      dragging: false
    }
  },
  props: {
    url: {
      type: String,
      required: true,
      default () { return '' }
    },
    loop: {
      type: Boolean,
      default: true
    }
  },
  async mounted () {
    this.loadZipOfJpg()
    this.resize()
    this.$events.on('gotoFrame', (event) => {
      // console.log('Pre')
      // console.log('Target', this.playHead.target)
      // console.log('Event Frame', event.frame)
      if (this.playHead) {
        const tx = event.frame
        if (event.direct) {
          this.playHead.target = tx
          this.playHead.value = tx
        } else if (event.action) {
          this.playHead.target += 60 * (event.action === 'next' ? 1 : -1)
          // this.playHead.target -= Math.abs(this.playHead.target % (this.frames.length - 1)) - event.frame
        } else {
          const diff = event.frame - (this.playHead.target % (this.frames.length - 1))
          this.playHead.speed = (event.speed || 2)
          if (Math.abs(diff) > this.frames.length / 2) {
            this.playHead.target -= (this.frames.length - Math.abs(diff)) * Math.sign(diff)
          } else {
            this.playHead.target += diff
          }
        }
        this.$emit('update', tx)
      }
    })
  },

  destroyed () {
    RAF.remove(this.render)
  },

  watch: {
    viewPort () {
      this.resize()
    },
    imageDataArray: {
      handler () {
        this.forceRender = true
      },
      deep: true
    },
    imageArray: {
      handler () {
        this.forceRender = true
      },
      deep: true
    },
    alphaChannelImageArray: {
      handler () {
        this.forceRender = true
      },
      deep: true
    },
    width: {
      handler () {
        this.forceRender = true
      },
      deep: true
    },
    height: {
      handler () {
        this.forceRender = true
      },
      deep: true
    }
  },
  methods: {
    resize () {
      const ratio = Math.min(this.viewPort.width / this.width, this.viewPort.height / this.height)
      this.width = (this.width * ratio)
      this.height = (this.height * ratio)
    },
    imagePromise (file) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          resolve(img)
        }
        img.onerror = (e) => {
          reject(new Error('Cannot natively decode image'))
        }
        img.src = file
      })
    },
    async loadZipOfJpg () {
      const response = await axios.get(this.url, { responseType: 'arraybuffer' })
      // color
      const zip = new JSZip()
      await zip.loadAsync(response.data)

      let color = filter(sortBy(zip.filter(f => f.indexOf('transparency') === -1), ['name']), { dir: false })
      color = filter(color, (img) => {
        return img.name.indexOf('__MACOSX') === -1 && img.name.indexOf('DS_STORE') === -1 && img.name.indexOf('/.') === -1
      })

      const frames = new Array(color.length)

      const transparency = filter(sortBy(zip.filter(f => f.indexOf('transparency') !== -1), ['name']), { dir: false })
      const alphaChannel = new Array(transparency.length)

      for (let i = 0; i < color.length; i++) {
        let file = await color[i].async('base64')
        // console.log(file)
        this.imagePromise('data:image/jpeg;base64,' + file).then(img => {
          this.$set(frames, i, img)
          return null
        }).catch(e => { throw e })

        if (transparency[i]) {
          file = await transparency[i].async('base64')
          this.imagePromise('data:image/jpeg;base64,' + file).then(img => {
            this.$set(alphaChannel, i, img)
            return null
          }).catch(e => { throw e })
        }
      }
      this.imageArray = frames
      this.alphaChannelImageArray = alphaChannel
      this.imageDataArray = []

      // console.log('init image scrubber')

      await this.$nextTick()

      if (this.$refs.canvas) {
        this.initImageScrubber()
      }
    },
    initImageScrubber () {
      this.$emit('loaded')
      this.prevMousePosition = { x: 0, y: 0 }
      this.mousePosition = { x: 0, y: 0 }
      this.playHead = new ElasticNumber(0)
      this.currentFrame = 0
      this.playHead.speed = 2
      this.time = new Date().getTime()
      this.ctx = this.$refs.canvas.getContext('2d')
      // document.addEventListener('wheel', (e) => {
      //   this.playHead.target += (e.deltaY * 0.1)

      //   if (!this.loop) {
      //     if (this.playHead.target <= 0) {
      //       this.playHead.target = 0
      //     }

      //     if (this.playHead.target >= this.frames.length - 1) {
      //       this.playHead.target = this.frames.length - 1
      //       console.log('looping', this.playHead.target)
      //     }
      //   } else {
      //     this.playHead.value = this.absmod(Math.floor(this.playHead.value), this.frames.length - 1)
      //   }

      //   // console.log(this.playHead.target)
      // })
      RAF.add(this.render)
    },
    render () {
      const hasImageArray = (this.imageArray && this.imageArray.length > 0)
      const hasImageDataArray = (this.imageDataArray && this.imageDataArray.length > 0)
      this.frames = hasImageArray ? this.imageArray : hasImageDataArray ? this.imageDataArray : null
      if ((!hasImageArray && !hasImageDataArray) || !frames) return

      const now = new Date().getTime()
      const deltaTime = (now - this.time) / 1000

      const xDelta = this.prevMousePosition.x - this.mousePosition.x
      this.prevMousePosition.x = this.mousePosition.x
      this.prevMousePosition.y = this.mousePosition.y
      if (this.mouseDown) {
        this.playHead.target -= (xDelta * 0.3)
        if (this.loop) {
          this.scrubFrame = this.absmod(Math.floor(this.playHead.value), this.frames.length - 1)
        } else {
          this.playHead.target -= (xDelta * 0.3)
          if (this.playHead.target <= 0) {
            this.playHead.target = 0
          }

          if (this.playHead.target >= this.frames.length - 1) {
            this.playHead.target = this.frames.length - 1
          }
          this.scrubFrame = Math.floor(this.playHead.value)
        }
      }
      // console.log(this.playHead.target)
      this.playHead.update(deltaTime)
      // console.log(Math.abs(this.playHead.value - this.playHead.target))
      if (Math.abs(this.playHead.value - this.playHead.target) < 1 && !this.mouseDown) {
        this.playHead.target = this.playHead.value // cut the slow easing to avoid jittering frames
      }

      const prevFrame = this.currentFrame
      if (this.loop) {
        this.currentFrame = this.absmod(Math.floor(this.playHead.value), this.frames.length - 1)
      } else {
        this.currentFrame = Math.floor(this.playHead.value)
        if (this.currentFrame <= 0) {
          this.currentFrame = 0
        }

        if (this.currentFrame >= this.frames.length - 1) {
          this.currentFrame = this.frames.length - 1
        }
      }
      if (prevFrame !== this.currentFrame || this.forceRender) {
        // console.log('render', this.currentFrame)
        this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)

        if (hasImageDataArray && this.frames[this.currentFrame]) {
          this.ctx.putImageData(this.frames[this.currentFrame], 0, 0)
        }

        if (hasImageArray) {
          if (this.alphaChannelImageArray && this.alphaChannelImageArray[this.currentFrame]) {
            // this.ctx.globalCompositeOperation = 'destination-in'
            this.ctx.drawImage(this.alphaChannelImageArray[this.currentFrame], 0, 0, this.width, this.height)

            const imageData = this.ctx.getImageData(0, 0, this.ctx.canvas.width, this.ctx.canvas.height)
            for (let i = 0, n = imageData.data.length; i < n; i += 4) {
              imageData.data[i + 3] = imageData.data[i]
            }
            this.ctx.putImageData(imageData, 0, 0)
            this.ctx.globalCompositeOperation = 'source-in'
          }

          if (this.frames[this.currentFrame]) {
            this.ctx.drawImage(this.frames[this.currentFrame], 0, 0, this.width, this.height)
          }
          this.ctx.globalCompositeOperation = 'source-over'
        }

        this.$emit('update', this.currentFrame)
        // console.log(this.currentFrame, this.frames.length)
      }
      this.forceRender = false

      this.time = now
    },
    absmod (n, m) {
      return ((n % m) + m) % m
    },
    getMousePositionX (event, touchIndex = 0) {
      if (event.touches && event.touches.length > 0) {
        return -event.touches[touchIndex].pageX
      }
      return -event.pageX
    },
    getMousePositionY (event, touchIndex = 0) {
      if (event.touches && event.touches.length > 0) {
        return event.touches[touchIndex].pageY
      }
      return event.pageY
    },
    onMouseMove (e) {
      this.dragging = this.mouseDown
      this.$emit('drag', this.dragging)
      this.mousePosition.x = this.getMousePositionX(e)
      this.mousePosition.y = this.getMousePositionY(e)
    },
    onMouseDown (e) {
      this.mouseDown = true
      this.mousePosition.x = this.getMousePositionX(e)
      this.mousePosition.y = this.getMousePositionY(e)
      this.prevMousePosition.x = this.mousePosition.x
      this.prevMousePosition.y = this.mousePosition.y
    },
    onMouseUp () {
      this.mouseDown = false
    }
  }
}
</script>

<style lang="scss" scoped>
.image-scrubber {
  position: relative;
  z-index: 1;
  cursor: pointer;

  &.grabbing {
    cursor: grabbing;
  }

  .shadows {
    position: absolute;
    bottom: 16%;
    right: 8%;
    left: 8%;
    height: 100px;
    background-color: $c-black-60;
    filter: blur(40px);
    pointer-events: none;

    @include breakpoint('sm-and-down') {
      bottom: 20%;
      height: 50px;
    }
  }
}

canvas {
  width: 100%;

  @include breakpoint('sm-and-down') {
    transform: scale(0.8);
  }
}
</style>
