<template>
  <component
    v-on="$listeners"
    v-bind="$attrs"
    :is="tag"
    :class="{ decoration }"
    type="button"
    class="button-text"
  >
    <p class="original"><slot></slot></p>
    <p class="dark"><slot></slot></p>
    <p class="light"><slot></slot></p>
    <v-fade-transition mode="out-in">
      <div v-show="underline" class="underline"></div>
    </v-fade-transition>
  </component>
</template>

<script>
export default {
  name: 'ButtonText',
  props: {
    tag: { type: String, default: 'button' },
    underline: { type: Boolean, required: false, default: false },
    decoration: { type: Boolean, required: false, default: true }
  }
}
</script>

<style lang="scss" scoped>
.button-text {
  text-decoration: none;
  position: relative;
  outline: none;
  opacity: 1;
  letter-spacing: 2.4px;
  font: 12px/15px futura-pt;

  .underline {
    height: 1px;
    background-color: $c-white;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    opacity: 0.5;
    transition: all 0.5s;
  }

  &.decoration {
    &::before {
      content: '.';
      position: absolute;
      left: -8px;
      bottom: 0;
      opacity: 0.5;
      transition: opacity 0.5s;
    }

    &::after {
      content: '.';
      position: absolute;
      right: -8px;
      bottom: 0;
      opacity: 0.5;
      transition: opacity 0.5s;
    }
  }

  &:hover {
    .underline {
      opacity: 1;

      @include breakpoint ('xs-only') {
        opacity: 0;
      }
    }

    &.decoration::before,
    &.decoration::after {
      opacity: 1;

      @include breakpoint ('xs-only') {
        opacity: 0;
      }
    }

    .light {
      width: 100%;

      @include breakpoint ('xs-only') {
        width: 0;
      }
    }
  }
}

.original {
  opacity: 0;
  visibility: hidden;
  width: auto;
  font: 12px/15px futura-pt;
  text-transform: uppercase;
}

.light,
.dark {
  position: absolute;
  top: 0;
  left: 0;

  user-select: none;
  pointer-events: none;
  overflow: hidden;

  width: 100%;
  height: 100%;

  position: absolute;
  white-space: nowrap;
  text-transform: uppercase;
  font: 12px/15px futura-pt;
}

.dark {
  color: $c-white-60;
}

.light {
  width: 0;
  height: 100%;
  overflow: hidden;
  color: $c-white;
  transition: width 0.5s;
}
</style>
