<template>
  <div>
    <slot/>
  </div>
</template>

<script>
import {
  SphereBufferGeometry,
  RepeatWrapping,
  MeshBasicMaterial,
  BackSide,
  Mesh,
  Vector3,
  LinearFilter,
  TextureLoader,
  VideoTexture,
  RGBFormat
} from 'three'

import { gsap } from 'gsap'

export const textureLoader = new TextureLoader()

const getVideoTexture = (src, onLoad, _options = {}, onError) => {
  const options = {
    loop: true,
    muted: true,
    autoplay: true,
    playsinline: true,
    crossOrigin: 'anonymous',
    ..._options
  }

  const video = document.createElement('video')
  video.loop = options.loop
  video.autoplay = options.autoplay
  video.playsinline = options.playsinline
  video.crossOrigin = options.crossOrigin
  video.muted = options.muted

  if (options.playsinline) {
    video.setAttribute('playsinline', '')
    video.setAttribute('webkit-playsinline', '')
  }
  video.src = src
  if (options.autoplay) {
    const onClick = () => {
      video.play().then(() => {}).catch(() => {}) //eslint-disable-line
      window.removeEventListener('click', onClick)
    }
    window.addEventListener('click', onClick())
  }

  const texture = new VideoTexture(video)
  texture.minFilter = texture.magFilter = LinearFilter
  texture.format = RGBFormat

  video.addEventListener('loadeddata', () => {
    onLoad()
  })

  return { texture, video }
}

export default {
  name: 'PanoImage',
  inject: ['viewer'],
  props: {
    src: { type: String },
    initialPoint: { type: String },
    initialRotation: { type: String },
    initialCamera: { type: String },
    isVideo: { type: Boolean, default: false },
    videoConfig: { type: Object, default: () => ({}) },
    teleport: { type: Boolean, default: false },
    transitionTime: { type: Number, default: 1 }
  },
  created () {
    this.type = 'panorama'
    this.geometry = new SphereBufferGeometry(5000, 60, 40)
    if (this.isVideo) {
      const { texture, video } = getVideoTexture(this.src, this.onLoad, this.videoConfig)
      this.texture = texture
      this.video = video
      this.$events.emit('loading-start')
    } else {
      this.texture = textureLoader.load(this.src, this.onLoad, undefined, this.onError)
    }
    this.texture.generateMipmaps = false
    this.texture.wrapS = RepeatWrapping
    this.texture.repeat.x = -1
    this.texture.offset.x = 1
    this.material = new MeshBasicMaterial({
      opacity: 0,
      transparent: true,
      depthWrite: false,
      depthTest: false,
      map: this.texture,
      side: BackSide
    })

    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.renderOrder = -1

    this.addToScene()
  },
  beforeDestroy () {
    if (!this.viewer.debugObserved.has(this.type)) {
      this.viewer.debugObserved.delete(this.type)
    }
  },
  watch: {
    'viewer.webgl.scene': {
      immediate: true,
      handler () {
        this.addToScene()
      }
    },
    'viewer.debug' (debugMode) {
      this.debugMode = debugMode === this.type
    }
  },
  methods: {
    teleportAnimation (mode) {
      const direction = new Vector3()
      this.viewer.camera.getWorldDirection(direction)
      this.mesh.position.addScaledVector(direction, mode === 'enter' ? 1000 : 0)
      this.savedPosition = this.mesh.position.clone()

      gsap.to({ time: 0, delay: mode === 'enter' ? 0 : 0.25 }, {
        time: -1000,
        duration: this.transitionTime,
        onUpdate (mesh, position) {
          const [target] = this.targets()
          mesh.position.copy(position.clone().addScaledVector(direction, target.time))
        },
        onUpdateParams: [this.mesh, this.savedPosition],
        onStart: () => {
          this.moveCameraToInitialPoint(this.transitionTime * 0.75)
        }
      })
    },
    leave () {
      return new Promise((resolve) => {
        window.requestAnimationFrame(() => {
          this.$emit('leave-fade-start')

          if (this.teleport) {
            this.teleportAnimation('leave')
          }

          this.mesh.renderOrder = 0

          gsap.to(this.material, {
            opacity: 0,
            duration: this.transitionTime * 0.8,
            delay: this.transitionTime * 0.2,
            onComplete: () => {
              this.$emit('leave-fade-end')
              if (this.added) {
                this.viewer.webgl.observed && this.viewer.webgl.observed.delete(this)
                this.mesh.geometry.dispose()
                this.mesh.material.dispose()
                this.viewer.webgl.scene.remove(this.mesh)
              }
              resolve()
            }
          })
        })
      })
    },
    addToScene () {
      if (!this.added && this.viewer.webgl.scene && this.mesh && this.textureLoaded) {
        this.added = true
        this.viewer.webgl.scene.add(this.mesh)
        this.viewer.webgl.observed.set(this, this)
        if (!this.viewer.debugObserved.has(this.type)) {
          this.viewer.debugObserved.set(this.type, {
            name: this.type,
            key: 'Controls',
            toggle: false
          })
        }

        // wait for texture to be uploaded on GPU
        window.requestAnimationFrame(() => {
          this.$emit('enter-fade-start')

          if (this.teleport) {
            this.teleportAnimation('enter')
          } else {
            this.moveCameraToInitialPoint()
          }

          gsap.to(this.material, {
            opacity: 1,
            duration: this.transitionTime,
            onComplete: () => {
              this.$emit('enter-fade-end')
            }
          })
        })
      }
    },
    moveCameraToInitialPoint (time = 0) {
      if (this.initialCamera) {
        this.viewer.controls.lookAtLatLon(...this.initialCamera.split(','), time)
      } else if (this.initialPoint) {
        this.viewer.controls.lookAt(...this.initialPoint.split(','), time)
      } else {
        this.viewer.controls.checkBounds()
        if (this.teleport) {
          this.viewer.controls.resetLatitude(time)
        }
      }
    },
    onError () {
      console.log('Texture not loaded', this.src)
    },
    onLoad () {
      if (this.isVideo) {
        this.$events.emit('loading-end')
      }
      window.requestAnimationFrame(() => {
        this.texture.minFilter = this.texture.magFilter = LinearFilter
        this.texture.needsUpdate = true
        this.textureLoaded = true
        this.addToScene()
      })
    }
  }
}
</script>
