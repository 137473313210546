<template>
  <v-fade-transition mode="out-in">
    <div v-if="visible" :key="$language.current" class="banner">
      <div class="text-center text-md-left">
        <div v-if="$language.current !== 'ko'">
          <p>
            <span>This website uses technical cookies (necessary for its proper functioning).</span>
            <span>For more information about the cookies used by the site and to manage individual cookies, you can consult</span>
            <strong>
              <router-link :to="{ name: 'CookiePage' }">the complete cookie policy at this link</router-link>
            </strong>
            <span>.</span>
          </p>
        </div>
        <div v-else>
          <p>
            <span>이 홈페이지는 (홈페이지가 올바르게 기능하는데 필요한) 기술 쿠키를 사용하고 있습니다.</span>
            <span>페르노리카 홈페이지가 사용하고 있는 기술 쿠키나 쿠키를 개별적으로 관리하는 방법에 대해 좀 더 자세히 알고 싶을 경우</span>
            <strong>
              <router-link :to="{ name: 'CookiePage' }">링크의 쿠키 정책 전문을 참고해주시기 바랍니다</router-link>
            </strong>
          </p>
        </div>
      </div>
      <div v-if="$language.current !== 'ko'">
        <div class="buttons">
          <button-text @click="onDisagree()" type="button" :decoration="false" class="disagree"> Disagree</button-text>
          <button-text @click="onAgree()" type="button" :decoration="false" class="agree active">Agree</button-text>
        </div>
      </div>
      <div v-else>
        <div class="buttons">
          <button-text @click="onDisagree()" type="button" :decoration="false" class="disagree">거부</button-text>
          <button-text @click="onAgree()" type="button" :decoration="false" class="agree active">허용</button-text>
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import ButtonText from '@/atoms/ButtonText.vue'

const getOption = (name, defaultValue) => localStorage.getItem(name) === null ? defaultValue : localStorage.getItem(name)

export default {
  name: 'CookieBanner',
  components: { ButtonText },
  data () {
    return {
      visible: localStorage.getItem('show-banner') === 'true',
      analytics: getOption('analytics-consent') || null
    }
  },
  methods: {
    onDisagree () {
      this.visible = false
      localStorage.setItem('show-banner', this.visible)
      localStorage.setItem('analytics-consent', false)
      this.$root.toggleAnalytics()
    },
    onAgree () {
      this.visible = false
      localStorage.setItem('show-banner', this.visible)
      localStorage.setItem('analytics-consent', true)
      this.$root.toggleAnalytics()
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: $z-cookiebanner;
  min-width: 65%;
  max-width: 95%;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #ffffff1d;
  border-radius: 14px;
  padding: 12px 24px;
  background-color: $c-blue;
  color: $c-white;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint ('sm-and-down') {
    min-width: 95%;
    flex-direction: column;
    justify-content: center;
  }
}

a {
  color: $c-gold;
  text-decoration: none;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 80px;

  @include breakpoint ('sm-and-down') {
    margin-left: 0;
    margin-top: 16px;
  }
}

.disagree {
  margin-right: 20px;
}

.agree {
  ::v-deep {
    .light {
      color: $c-gold;
    }

    .dark {
      color: $c-gold;
      opacity: 0.6;
    }
  }
}

::v-deep .button-text.active .light {
  width: 100%;
}
</style>
