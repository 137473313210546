import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from '../routes'

// docs: https://router.vuejs.org/en/
Vue.use(VueRouter)

function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    return next({ ...to, query: from.query })
  }
  return next()
})

export default router
