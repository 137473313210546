<template>
  <div class="d-none d-md-block">
    <button-text v-if="!$root.menuOpen" @click="onClick(true)" :class="{'lotte-app': $root.isLotteApp}" class="open">{{$root.translations[$language.current].rooms}}</button-text>
    <button-text v-else @click="onClick(false)" :class="{'lotte-app': $root.isLotteApp}" class="close">{{$root.translations[$language.current].close}}</button-text>
  </div>
</template>

<script>
import ButtonText from '@/atoms/ButtonText.vue'

export default {
  name: 'MenuCta',
  components: { ButtonText },
  methods: {
    onClick (state) {
      this.$root.menuOpen = state
      this.$root.pushAnalyticsEvent('click_on_menu', 'UI button', 'click', `Menu ${state ? 'open' : 'close'}`, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.open,
.close {
  position: fixed;
  pointer-events: all;
  right: 40px;
  top: 30px;
  z-index: $z-ui-el;
  cursor: pointer;
  user-select: none;

  @include breakpoint('sm-and-down') {
    right: 20px;
    top: 30px;
  }

  &.lotte-app {
    top: 65px;
  }
}
</style>
