<template>
  <v-slide-y-reverse-transition mode="out-in">
    <div v-if="$vuetify.breakpoint.smAndDown" v-click-outside="setItems" class="menu-mobile">
      <div
        :style="{
          'min-height': `${menuMinHeight}px`
        }"
        class="menu-content"
      >
        <div v-if="!!items" class="content">
          <div v-for="(product, index) in items" :key="index" class="sub-item my-1">
            <router-link
              v-if="itemsType === 'languages'"
              :to="{ params: { language: product.code } }"
              @click.native="onChangeLanguage(product.code)"
            >
              {{product.label}}
            </router-link>
            <button-text
              v-if="itemsType === 'shop'"
              :tag="product[`${$language.current}ShopURL${$root.embedMode}`] ? 'a' : 'router-link'"
              :to="product[`${$language.current}ShopURL${$root.embedMode}`]
                ? undefined
                : {name:'Room',params:{roomSlug:product.roomSlug,contentSlug:product._slug}
              }"
              :href="product[`${$language.current}ShopURL${$root.embedMode}`]
                ? product[`${$language.current}ShopURL${$root.embedMode}`]
                : undefined
              "
              :target="product[`${$language.current}ShopURL${$root.embedMode}`]
                ? '_blank'
                : undefined
              "
              :decoration="false"
              class="my-2"
              @click.native="onProductClick(product)"
            >
              {{product[$language.current + 'Title']}}
            </button-text>
          </div>
        </div>
      </div>
      <div :class="{ active: itemsType === 'languages' }" class="nav-item">
        <button-text @click="setItems('languages')" :decoration="false" :underline="itemsType === 'languages'">{{currentLanguage.label}}</button-text>
      </div>
      <div :class="{ active: itemsType === 'shop' }" class="nav-item borders">
        <button-text @click="setItems('shop')" :decoration="false" :underline="itemsType === 'shop'">{{$root.translations[$language.current].shop}}</button-text>
      </div>
      <div :class="{ active: itemsType === 'menu' && $root.menuOpen }" class="nav-item">
        <button-text @click="onMenuToggle()" :decoration="false" :underline="itemsType === 'menu'">{{ $root.menuOpen ? $root.translations[$language.current].close : $root.translations[$language.current].rooms }}</button-text>
      </div>
    </div>
  </v-slide-y-reverse-transition>
</template>

<script>
import { AVAILABLE_LOCALES } from '@/assets/data/constants'
import GetShop from '@/graphql/GetShop.gql'
import ButtonText from '@/atoms/ButtonText.vue'

export default {
  name: 'MenuMobile',
  components: { ButtonText },
  apollo: {
    Rooms: {
      query: GetShop,
      skip () {
        return this.$vuetify.breakpoint.mdAndUp
      },
      async result () {
        let temp = []
        for (let i = 0; i < this.Rooms.items.length; i++) {
          const room = this.Rooms.items[i]
          const products = room.hotspot.filter((h) => {
            if (h.contentId.type !== 'Product') return false
            if (!h.contentId.visibleOnlyEmbedModes) return true
            if (h.contentId.visibleOnlyEmbedModes.includes(this.$root.embedMode)) return true
            return false
          })
          const map = products.map(({ contentId }) => {
            return { ...contentId, roomSlug: room._slug }
          })
          temp = temp.concat(map)
        }
        this.allProcuts = temp
      }
    }
  },
  data () {
    return {
      allProcuts: [],
      items: null,
      prevItemsType: '',
      itemsType: ''
    }
  },
  mounted () {
    this.$events.on('drag-start', this.setItems)
  },
  computed: {
    menuMinHeight () {
      let height = 56
      if (this.items) {
        const keys = Object.keys(this.items)
        height = keys.length * 55
      }
      return height
    },
    languages () {
      return AVAILABLE_LOCALES[`embedMode${this.$root.embedMode}`]
    },
    otherLanguages () {
      return this.languages.filter(l => l.code !== this.$language.current)
    },
    currentLanguage () {
      return this.languages.find(l => l.code === this.$route.params.language)
    },
    products () {
      return this.allProcuts
    }
  },
  methods: {
    setItems (type) {
      this.prevItemsType = this.itemsType
      this.itemsType = type
      if (type === this.prevItemsType && this.items) this.items = null
      else if (type === 'languages') this.items = this.otherLanguages
      else if (type === 'shop') this.items = this.products
      else this.items = null
    },
    onChangeLanguage (language) {
      this.$root.pushAnalyticsEvent('click_on_language', 'Menu mobile', 'click', `${language}`, '')
      this.setItems('')
    },

    onShopProductClick (product) {
      this.$root.pushAnalyticsEvent('click_on_shop_product', 'Menu mobile', 'click', `${product.enTitle}`, '')
      this.setItems('')
      this.$root.menuOpen = false
    },
    onMenuToggle () {
      this.setItems('menu')
      this.$root.menuOpen = !this.$root.menuOpen
      this.$root.pushAnalyticsEvent('click_on_menu', 'Menu mobile', 'click', `Menu ${this.$root.menuOpen ? 'open' : 'close'}`, '')
    },
    onProductClick (product) {
      this.$root.pushAnalyticsEvent('click_on_shop_product', 'UI button', 'click', `${product.enTitle}`, '')
      this.setItems('')
    }
  },
  watch: {
    currentLanguage: {
      handler (value) {
        if (!this.languages?.includes(value)) {
          this.$router.replace({ params: { language: 'en' } })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-mobile {
  position: fixed;
  z-index: $z-menu-mobile;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.nav-item {
  width: 33%;
  height: 40px;
  // padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.borders {
    border-left: 1px solid $c-white-30;
    border-right: 1px solid $c-white-30;
  }

  &.active ::v-deep {
    .light {
      width: 100%;
    }

    .underline {
      opacity: 1;
    }
  }
}

.menu-content {
  width: 100%;
  position: fixed;
  z-index: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  border-top: 1px solid $c-white-30;
  background-color: $c-black-100;
  transition: all 0.4s;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 56px);
    padding: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    /* stylelint-disable */
    ::v-deep .button-text {
      max-width: 85% !important;
      height: 1.25em;

      .underline {
        left: -10px;
        right: -10px;
      }

      p {
        text-overflow: ellipsis;
      }
    }
    /* stylelint-enable */
  }
}

.sub-item {
  width: 100%;

  a,
  button {
    display: block;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: $c-white;
    font: 14px/16px futura-pt;
    opacity: 0.6;

    &.active {
      pointer-events: none;
      opacity: 1;
    }
  }
}

</style>
